import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { cache } from '../cache'
import {
  ActiveEnvironment,
  getActiveEnvironmentLabelForRouting
} from '@local/environment-switcher-utils'
import { useCallback } from 'react'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

const serverUrl = '/api/service/depot-bff/v1/graphql'
const secondaryBaseUrl = `https://developer.sandbox.eng.toasttab.com`
const secondaryUrl = secondaryBaseUrl.concat(serverUrl)

export const primaryApolloClient = new ApolloClient<NormalizedCacheObject>({
  cache: cache,
  uri: serverUrl
})

const secondaryApolloClient = new ApolloClient<NormalizedCacheObject>({
  cache: cache,
  uri: secondaryUrl,
  credentials: 'include'
})

export function useEnvBasedApolloClient() {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const activeEnv = getActiveEnvironmentLabelForRouting(mode ?? env)

  const apolloClient = useCallback(
    (activeEnv: ActiveEnvironment) => {
      return activeEnv === 'secondary'
        ? secondaryApolloClient
        : primaryApolloClient
    },
    [activeEnv]
  )

  // When in storybook and in the tests we cannot set a custom dynamic client.
  // By returning undefined, Apollo keeps using the default client value passed down from mockApolloProvider in those instances.
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
    return undefined

  return apolloClient(activeEnv)
}
