import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'

type TokenContextValue = {
  token: string | undefined
}

export const TokenContext = createContext<TokenContextValue>({
  token: undefined
})

export const TokenContextProvider = ({ children }: any) => {
  const { auth } = useDepotBanquetProps()
  const [token, setToken] = useState<string>()
  useMemo(() => {
    auth
      ?.getTokenSilently()
      .then((token: string) => setToken(token))
      .catch((err: any) => {
        console.error(err)
        setToken(undefined)
      })
  }, [auth])

  const providerValue = useMemo(
    () => ({
      token
    }),
    [auth, token]
  )
  return (
    <TokenContext.Provider value={providerValue}>
      {children}
    </TokenContext.Provider>
  )
}

export function useTokenContext() {
  return useContext(TokenContext)
}
