import {
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react'

import {
  useSortBy,
  useTable,
  usePagination as useReactTablePagination
} from 'react-table'
import { Table, Head, Body, Row } from '@toasttab/buffet-pui-table'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { Pagination, usePagination } from '@toasttab/buffet-pui-pagination'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { ExportIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'

import { NoRestaurantsMessage } from '../NoRestaurantsMessage'
import { getTableRows, getHeaderCells } from './utils'
import { ConnectedRestaurants, TableParams, SearchParams } from '../types'
import { Filters } from '../Filters'
import { ConnectedRestaurantsSearch } from '../ConnectedRestaurantsSearch'
import { exportConnectedRestaurantsResult } from '../api/exportConnectedRestaurant'
import { useTableColumns } from './utils/useTableColumns'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import {
  isSecondaryEnv,
  useTokenContext
} from '@local/environment-switcher-utils'

export interface ConnectedRestaurantsTableProps {
  testId?: string
  connectedRestaurants: ConnectedRestaurants
  connectedRestaurantsLoading: boolean
  partnerGuid: string
  tableParams: TableParams
  setTableParams: Dispatch<SetStateAction<TableParams>>
  searchParams: SearchParams
  setSearchParams: Dispatch<SetStateAction<SearchParams>>
  debouncedSearchTerm: string
}

export const ConnectedRestaurantsTable = ({
  testId = `connected-restaurants-table`,
  connectedRestaurants,
  connectedRestaurantsLoading,
  partnerGuid,
  tableParams,
  setTableParams,
  searchParams,
  setSearchParams,
  debouncedSearchTerm
}: ConnectedRestaurantsTableProps) => {
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()
  const env = getCurrentEnvironment()
  const { columns } = useTableColumns()
  const { token } = useTokenContext()

  const data: any = useMemo(() => {
    return connectedRestaurants.results.map(
      ({
        restaurantName,
        restaurantLocationName,
        restaurantGuid,
        externalRestaurantRef,
        externalGroupRef,
        userEmail,
        createdDate
      }) => ({
        restaurantName: { restaurantName, restaurantLocationName },
        restaurantGuid,
        externalRestaurantRef,
        externalGroupRef,
        userEmail,
        createdDate: format(
          new Date(parseInt(createdDate)),
          Formats.date.long,
          'en-US'
        )
      })
    )
  }, [connectedRestaurants])

  const {
    headers,
    page,
    state: { pageIndex },
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetSortBy: false,
      initialState: { sortBy: [{ id: 'createdDate', desc: true }] }
    },
    useSortBy,
    useReactTablePagination
  )

  const onChangePage = useCallback(
    (currentPage: number) => {
      if (searchParams.term !== '') {
        setSearchParams({
          ...searchParams,
          page: currentPage + 1
        })
      } else {
        setTableParams({ ...tableParams, page: currentPage + 1 })
      }
    },
    [tableParams, searchParams, setTableParams, setSearchParams]
  )

  const paginationProps = usePagination({
    currentPage: pageIndex,
    totalPageCount: connectedRestaurants?.lastPageNum || 1,
    visiblePages: 10,
    onChangePage
  })

  // when the pagination for search changes, we need to update
  // the pagination index for the table
  useEffect(() => {
    const isSearchPagination =
      searchParams.term !== '' &&
      paginationProps.currentPage !== searchParams.page - 1

    const isDefaultPagination =
      searchParams.term === '' &&
      paginationProps.currentPage !== tableParams.page - 1

    if (isSearchPagination) paginationProps.gotoPage(searchParams.page - 1)
    else if (isDefaultPagination) {
      paginationProps.gotoPage(tableParams.page - 1)
    }
  }, [searchParams, tableParams, paginationProps])

  const renderTable = () => {
    return connectedRestaurantsLoading ? (
      <Skeleton className='h-screen w-full' />
    ) : (
      <Table
        className='table table-striped table-bordered table-fixed'
        testId={testId}
      >
        <Head>
          <Row>{getHeaderCells(headers, tableParams, setTableParams)}</Row>
        </Head>
        <Body>
          {getTableRows(
            page,
            prepareRow,
            columns,
            'connected-restaurants-table-row'
          )}
        </Body>
      </Table>
    )
  }

  const TablePagination = () => (
    <div className='flex flex-col items-end my-4'>
      <Pagination
        testId='connected-restaurants-pagination'
        {...paginationProps}
      />
    </div>
  )

  const hasAppliedDateFilter =
    tableParams && (!!tableParams.createdFrom || !!tableParams.createdTo)

  const noFiltersApplied = !debouncedSearchTerm && !hasAppliedDateFilter

  const noResultsFound = connectedRestaurants.results.length === 0

  if (noFiltersApplied && noResultsFound) {
    return (
      <NoRestaurantsMessage
        setSearchParams={setSearchParams}
        tableParams={tableParams}
        setTableParams={setTableParams}
        partnerDoesNotHaveRestaurants
      />
    )
  }

  return (
    <div>
      <div className='pb-3 flex items-end'>
        <Filters
          testId='filters'
          tableParams={tableParams}
          setTableParams={setTableParams}
        />
        <ConnectedRestaurantsSearch
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <div className='ml-auto mb-2'>
          <Button
            variant={Button.Variant?.secondary}
            disabled={
              (noResultsFound || (isSecondaryEnv(mode ?? env) && !token)) ??
              false
            }
            iconLeft={<ExportIcon accessibility='decorative' />}
            onClick={() =>
              exportConnectedRestaurantsResult(
                partner,
                mode ?? env,
                tableParams,
                searchParams,
                token
              )
            }
          >
            Export
          </Button>
        </div>
      </div>
      {noResultsFound ? (
        <NoRestaurantsMessage
          setSearchParams={setSearchParams}
          tableParams={tableParams}
          setTableParams={setTableParams}
          partnerDoesNotHaveRestaurants={false}
        />
      ) : (
        <>
          <CardContainer>{renderTable()}</CardContainer>
          <TablePagination />
        </>
      )}
    </div>
  )
}
