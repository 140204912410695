import { ApolloProvider } from '@apollo/client'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { StrictMode } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams
} from 'react-router-dom'

import { ActivityLogPage } from '@local/activity-log'
import { ConnectedRestaurantsPage } from '@local/connected-restaurants'
import { CredentialsPage } from '@local/credentials'
import { HomePage } from '@local/homepage'
import { MarketplaceListingLandingPage } from '@local/marketplace-listing'
import { NotFoundPage } from '@local/depot-error-pages'

import useTrackPageUnload from './useTrackPageUnload'
import { EditListingPage } from '@local/marketplace-listing/src/components/EditListingPage/EditListingPage'
import { primaryApolloClient } from './useEnvBasedApolloClient'
import { TokenContextProvider } from '@local/environment-switcher-utils'

export function App() {
  return (
    <StrictMode>
      <ApolloProvider client={primaryApolloClient}>
        <TokenContextProvider>
          <SnackBarProvider>
            <div id='banquetPortalsContainer' />
            <div className='bg-gray-0 text-default'>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </div>
          </SnackBarProvider>
        </TokenContextProvider>
      </ApolloProvider>
    </StrictMode>
  )
}

function AppRoutes() {
  useTrackPageUnload()
  return (
    <Routes>
      <Route path='home' element={<HomePage />} />
      <Route path='credentials' element={<CredentialsPage />} />
      <Route
        path='connected-restaurants'
        element={<ConnectedRestaurantsPage />}
      />
      <Route
        path='marketplace-listing'
        element={<MarketplaceListingLandingPage />}
      />
      <Route path='marketplace-listing/edit' element={<EditListingPage />} />
      <Route path='activity-log' element={<ActivityLogPage />} />
      <Route path='user-management' element={<>User Management</>} />

      <Route path='v1/:oldPath?' element={<RedirectV1 />} />
      <Route path='' element={<Navigate to='/home' />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

/**
 * redirect any v1 urls to new url
 * * /v1 -> /home
 * * /v1/home -> /home
 */
export function RedirectV1() {
  const { oldPath = 'home' } = useParams()
  return <Navigate to={`/${oldPath}`} />
}
