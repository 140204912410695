import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { withScope, captureException } from '@sentry/browser'
import { ConnectedRestaurants } from '../../types/connected-restaurants.type'
import { GET_CONNECTED_RESTAURANTS_FOR_PARTNER } from '../queries/getConnectedRestaurants'
import { SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER } from '../queries/searchConnectedRestaurants'
import { TableParams } from '../../types'
import { SearchParams } from '../../types/search-params.type'
import {
  buildDepotRequestContext,
  useTokenContext
} from '@local/environment-switcher-utils'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useEnvBasedApolloClient } from '../../../../../src/app/useEnvBasedApolloClient'

export interface UseConnectedRestaurantsProps extends TableParams {
  activeEnv: string
  searchParams?: SearchParams
}

export const useConnectedRestaurants = ({
  activeEnv,
  searchParams,
  ...rest
}: UseConnectedRestaurantsProps) => {
  const { partner } = useDepotBanquetProps()
  const [connectedRestaurants, setConnectedRestaurants] =
    useState<ConnectedRestaurants>()
  const apolloClient = useEnvBasedApolloClient()
  const { token } = useTokenContext()

  const {
    data,
    loading: connectedRestaurantsLoading,
    error: connectedRestaurantsError,
    refetch
  } = useQuery(GET_CONNECTED_RESTAURANTS_FOR_PARTNER, {
    skip: !!searchParams?.term,
    client: apolloClient,
    context: buildDepotRequestContext(partner, activeEnv, token),
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    variables: {
      ...rest
    },
    onError: (apolloError) => {
      withScope((scope) => {
        scope.setTag('toast_component', 'useConnectedRestaurants')
        scope.setTag(
          'toast_function',
          'useQuery:GET_CONNECTED_RESTAURANTS_FOR_PARTNER'
        )
        scope.setLevel('warning')
        captureException(apolloError)
      })
    }
  })

  const { data: searchData, loading: searchConnectedRestaurantsLoading } =
    useQuery(SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER, {
      skip: !searchParams?.term,
      client: apolloClient,
      context: buildDepotRequestContext(partner, activeEnv, token),
      refetchWritePolicy: 'overwrite',
      fetchPolicy: 'network-only',
      variables: {
        term: searchParams?.term,
        page: searchParams?.page
      },
      onError: (apolloError) => {
        withScope((scope) => {
          scope.setTag('toast_component', 'useConnectedRestaurants')
          scope.setTag(
            'toast_function',
            'useQuery:SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER'
          )
          scope.setLevel('warning')
          captureException(apolloError)
        })
      }
    })

  useMemo(() => {
    if (searchData?.searchConnectedRestaurants) {
      setConnectedRestaurants(searchData.searchConnectedRestaurants)
    } else if (data?.getConnectedRestaurants) {
      setConnectedRestaurants(data.getConnectedRestaurants)
    }
  }, [data, searchData])

  return {
    connectedRestaurantsLoading,
    searchConnectedRestaurantsLoading,
    connectedRestaurantsError,
    connectedRestaurants,
    refetch
  }
}
