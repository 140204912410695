import { useEffect, useState } from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { useDebounce } from 'react-use'

import { ConnectedRestaurantsTable } from '../ConnectedRestaurantsTable'
import { useConnectedRestaurants } from '../api/hooks/useConnectedRestaurants'
import { SortDirection, SortField, SearchParams, TableParams } from '../types'
import { ServerErrorPage } from '@local/depot-error-pages'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'
import {
  getCurrentPartnerGuid,
  useTokenContext
} from '@local/environment-switcher-utils'
import { Partner } from '@toasttab/depot-banquet-props'

export interface ConnectedRestaurantsProps {
  testId?: string | number
  partner: Partner
}

export const initialTableParams = {
  page: 1,
  sortField: SortField.createdDate,
  sortDirection: SortDirection.DESC
}

export const initialSearchParams = {
  term: '',
  page: 1
}

export const ConnectedRestaurants = ({
  testId = `connected-restaurants`,
  partner
}: ConnectedRestaurantsProps) => {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const { token } = useTokenContext()

  const [tableParams, setTableParams] =
    useState<TableParams>(initialTableParams)

  const [searchParams, setSearchParams] =
    useState<SearchParams>(initialSearchParams)

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(
    initialSearchParams.term
  )

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchParams.term)
    },
    500,
    [setDebouncedSearchTerm, searchParams.term]
  )

  useEffect(() => {
    // after search, we should redirect the user to the first page
    setTableParams((previousState) => ({
      ...previousState,
      page: 1
    }))
  }, [searchParams, setTableParams])

  const {
    connectedRestaurants,
    connectedRestaurantsLoading,
    searchConnectedRestaurantsLoading,
    connectedRestaurantsError,
    refetch
  } = useConnectedRestaurants({
    activeEnv: mode ?? env,
    searchParams: { ...searchParams, term: debouncedSearchTerm },
    ...tableParams
  })
  useEffect(() => {
    if (token) {
      refetch({ mode })
    }
  }, [mode, token, refetch])

  if (connectedRestaurantsError) {
    return <ServerErrorPage />
  }

  return (
    <div data-testid={testId}>
      {connectedRestaurantsLoading && !connectedRestaurants && (
        <Skeleton testId='skeleton-loader' className='h-screen w-full' />
      )}
      {connectedRestaurants && (
        <ConnectedRestaurantsTable
          connectedRestaurants={connectedRestaurants}
          connectedRestaurantsLoading={
            connectedRestaurantsLoading || searchConnectedRestaurantsLoading
          }
          partnerGuid={getCurrentPartnerGuid(partner, mode ?? env) ?? ''}
          tableParams={tableParams}
          setTableParams={setTableParams}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          debouncedSearchTerm={debouncedSearchTerm}
        />
      )}
    </div>
  )
}
